import '../css/app.scss'

jQuery(document).ready(function ($) {
  function throttle(fn, wait) {
    var time = Date.now()
    return function () {
      if (time + wait - Date.now() < 0) {
        fn()
        time = Date.now()
      }
    }
  }

  const dataScroll = () => {
    let elts = document.querySelectorAll('[data-scroll]')
    if (window.scrollY >= 1) {
      elts.forEach((el) => {
        el.style.opacity = 1
      })
    } else {
      elts.forEach((el) => {
        el.style.opacity = 0
      })
    }
  }

  window.toggleMenu = (btn) => {
    btn.children[0].classList.toggle('menu-btn--open')
    document.querySelector('.menu-container').classList.toggle('bg-white')
    let menu = document.querySelector('.menu')
    menu.classList.toggle('pointer-events-none')
    menu.classList.toggle('opacity-0')
  }

  const parallax = () => {
    var scrolled = window.scrollTop
    var parallax = document.querySelector('.parallax')
    var bbox = parallax.getBoundingClientRect()
    var coords =
      (scrolled - parallax.getBoundingClientRect().height) * 0.4 + 'px'
    parallax.style.transform = 'translateY(' + coords + ')'
  }

  dataScroll()
  window.addEventListener('scroll', () => {
    // parallax()
    dataScroll()
  })

  // GALLERY
  let grid = document.querySelector('.grid-container')
  if (grid) {
    let observerOptions = {
      threshold: 0.3
    }
    let observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) entry.target.classList.add('opacity-100')
      })
    }
    let observer = new IntersectionObserver(observerCallback, observerOptions)
    Array.from(grid.children).forEach((child) => {
      observer.observe(child)
    })

    let loader = document.querySelector('.grid-loader')
    let iso
    imagesLoaded(grid, function () {
      loader.classList.add('hidden')
      iso = new Isotope(grid, {
        itemSelector: '.grid-item',
        percentPosition: true,
        masonry: {
          columnWidth: '.grid-sizer',
          gutter: 14
        }
      })
      iso.layout()
    })

    let previousFilter = document.querySelector('[data-filter="all"]')
    document.querySelector('.filters')?.addEventListener('click', (e) => {
      if (e.target.tagName == 'LI') {
        previousFilter.classList.remove('filter')
        e.target.classList.add('filter')
        iso.arrange({
          filter: `.filter-${e.target.dataset.filter}`
        })
        previousFilter = e.target
      }
    })
  }

  // contenu.twig
  document.querySelector('.content')?.addEventListener('click', function (e) {
    if (e.target.classList.contains('content__show')) {
      e.target.nextElementSibling.classList.add('max-h-[9999px]')
      e.target.classList.add('invisible')
    }
  })

  const isInputFilled = function () {
    let label = $(this).closest('.gfield').find('label')
    $(this).val()
      ? label.addClass('input-filled')
      : label.removeClass('input-filled')
  }
  $('.gform_wrapper').on('change', ':input', isInputFilled)

  $('.gform_wrapper').on('mousedown', 'fieldset label', function () {
    $('.gform_wrapper').addClass('focus-checkbox')
    $(this).closest('.gfield').addClass('focused-checkbox')
  })
  $('.gform_wrapper').on('mouseup', 'fieldset label', function () {
    $('.gform_wrapper').removeClass('focus-checkbox')
    $(this).closest('.gfield').removeClass('focused-checkbox')
  })
  $(document).on('gform_post_render', function (event, form_id, current_page) {
    let formWrapper = $(`#gform_wrapper_${form_id}`)

    if (formWrapper.hasClass('gform_validation_error')) {
      let fieldHeight = formWrapper
        .find('.form-submit .validation_message')
        .outerHeight()
      formWrapper.find(':input').each(isInputFilled)
      setTimeout(() => {
        formWrapper.find('[aria-invalid="true"]:first').focus()
      }, 0)
    }
  })
  // Lightgallery
  $('.lightgallery').each((index, gallery) => {
    lightGallery(gallery, {
      selector: '.lightgallery__item',
      share: false
    })
  })
})
